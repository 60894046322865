<template>
    <el-upload
        v-model:file-list="fileList"
        v-loading.fullscreen.lock="loading"
        class="media-upload"
        list-type="picture-card"
        :accept="accept"
        :http-request="onHttpRequst"
        :on-success="onUploadSuccess"
        :on-preview="onUploadPreview"
        :on-remove="emitFileChange"
        :limit="9"
        :on-exceed="onExceed"
        multiple
    >
        <el-icon><Plus /></el-icon>
    </el-upload>
</template>

<script setup>
import {ref, watch} from "vue";
import _ from "lodash";
import MediaPreviewCom from "@/components/mediaPreviewCom.vue";
import * as baseinfoApi from "@/api/baseinfo";
import showModal from "@/utils/modalUtil";
import {ElMessage} from "element-plus";

const props = defineProps({
    "modelValue": Array,
    "accept": String,
    "withThumbnail": {
        "type": Boolean,
        "default": (p) => p["accept"].includes("video"),
    }});
const emit = defineEmits(["update:modelValue"]);
const fileList = ref([]);
const loading = ref(false);

const unwatch = watch(() => props.modelValue, () => {
    fileList.value = (props["modelValue"] || []).map((f, idx) => {
        if (props["withThumbnail"]){
            return {"response": f, "url": f.thumbnail, "uid": idx, "status": "success"};
        } else {
            return {"response": {"type":"image", "thumbnail": f, "url": f}, "url": f, "uid":idx, "status": "success"};
        }
    });
});

function emitFileChange(){
    unwatch();
    if (props["withThumbnail"]){
        // [{type, thumbnail, url}]
        emit("update:modelValue", fileList.value.map(f => f.response).filter(Boolean));
    } else {
        // [url]
        // response可能为空，未知bug
        emit("update:modelValue", fileList.value.map(f => f.response?.url).filter(Boolean));
    }
}

async function capture(videoFile){
    const fileBlob = URL.createObjectURL(videoFile);

    const video = document.createElement("video");
    video.controls = true;
    video.src = fileBlob;
    await video.play();

    const canvas = document.createElement("canvas");
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    canvas.getContext("2d").drawImage(video, 0, 0);
    URL.revokeObjectURL(fileBlob);
    return new Promise((resolve) => {
        canvas.toBlob(resolve, "image/jpg");
    });
}

async function onHttpRequst(option) {
    loading.value = true;
    const ret = {"type": "image"};
    if (option.file.type.includes("video")){
        const thumbnailBlob = await capture(option.file);
        const thumbnailFile = new File([thumbnailBlob],
            `${option.file.name}-thumbnail.jpg`, {"type": thumbnailBlob.type},
        );
        const {"data":{presignedUrl, fileUrl}} = await baseinfoApi.getOssPresignedUrl("jpg");
        const resp = await baseinfoApi.putOssFile(presignedUrl, thumbnailFile);
        URL.revokeObjectURL(thumbnailBlob);
        if (!resp.ok){
            loading.value = false;
            const respText = await resp.text();
            console.error(`upload thumbnail error, ${resp.status} ${resp.statusText}:\n${respText}`);
            throw new Error(`文件上传失败: ${resp.status} ${resp.statusText}`);
        }
        ret.type = "video";
        ret.thumbnail = fileUrl;
    }
    const filetype = _.last(option.file.name.split("."));
    const {"data":{presignedUrl, fileUrl}} = await baseinfoApi.getOssPresignedUrl(filetype);
    const resp = await baseinfoApi.putOssFile(presignedUrl, option.file);
    if (!resp.ok){
        loading.value = false;
        const respText = await resp.text();
        console.error(`upload error, ${resp.status} ${resp.statusText}:\n${respText}`);
        throw new Error(`文件上传失败: ${resp.status} ${resp.statusText}`);
    }
    loading.value = false;
    ret.url = fileUrl;
    return ret;
}

function onUploadSuccess(ret, file){
    ret.thumbnail = ret.thumbnail || ret.url;
    file.url = ret.thumbnail;
    emitFileChange();
}

function onUploadPreview(file){
    showModal(MediaPreviewCom, {"src": file.response.url, "type": file.response.type});
}
function onExceed() {
    return ElMessage.success("每次最多上传9张图片");
}
</script>

<style scoped>
.media-upload :deep(.el-upload-list--picture-card){
    --el-upload-list-picture-card-size: var(--uploader-card-size, 90px);
}
.media-upload :deep(.el-upload--picture-card){
    --el-upload-picture-card-size: var(--uploader-card-size, 90px);
}
</style>