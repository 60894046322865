import request from "./_request";

export function loginXZQT(token) {
    return request.post("/api/account/login/xzqt", {token}, {"silence":true});
}

export function loginDingtalk(code, corpId) {
    return request.get("/api/account/login/dingtalk", {"params": {code, corpId},"silence":true});
}

export function loginDingtalkMobile(code, dingtalkId, corpId) {
    return request.get("/api/account/bind/dingtalk", {"params": {code, dingtalkId, corpId},"silence":true});
}

export function logout() {
    return request.get("/api/account/logout");
}

export function getAccountInfo() {
    return request.get("/api/account/info");
}

export function getAccountInfoSilence() {
    return request.get("/api/account/info", {"silence":true});
}

export function switchOrganization(organizationId) {
    return request.get("/api/account/switch/org", {"params":{organizationId}});
}
