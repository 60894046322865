import {createRouter, createWebHashHistory} from "vue-router";

const routes = [
    {"path": "/", "component": () => import("@/views/HomeView.vue")},

    // 作业控量
    {"path": "/:role/homework/add", "name": "HomeworkAdd", "props": true, "component": () => import("@/views/homework/HomeworkAddView.vue")},
    {"path": "/:role/homework/adds", "name": "HomeworkAdds", "props": true, "component": () => import("@/views/homework/HomeworkAddsView.vue")},
    {"path": "/:role/homework/guardiancomment", "name": "HomeworkGuardianComment", "props": true, "component": () => import("@/views/homework/HomeworkGuardianComment.vue")},
    {"path": "/:role/homework/list", "name": "HomeworkList", "props": true, "component": () => import("@/views/homework/HomeworkListView.vue")},
    {"path": "/:role/homework/panel", "name": "homeworkPanel", "props": true, "component": () => import("@/views/homework/HomeworkPanelView.vue")},
    {"path": "/:role/homework/detail/:homeworkId", "name": "HomeworkDetail", "props": true, "component": () => import("@/views/homework/HomeworkDetailView.vue")},
    {"path": "/:role/homework/edit/:homeworkId", "name": "HomeworkEdit", "props": true, "component": () => import("@/views/homework/HomeworkEditView.vue")},

    // 县域培训
    {"path": "/:role/xianyupeixun/list/:status?", "name": "XianyupeixunList", "props": true, "component": () => import("@/views/xianyu/XianyupeixunListView.vue")},
    {"path": "/:role/xianyupeixun/detail/:xianyupeixunId", "name": "XianyupeixunDetail", "props": true, "component": () => import("@/views/xianyu/XianyupeixunDetailView.vue")},
    {"path": "/:role/xianyupeixun/add", "name": "XianyupeixunAdd", "props": true, "component": () => import("@/views/xianyu/XianyupeixunAddView.vue")},
    {"path": "/:role/xianyupeixun/edit/:xianyupeixunId", "name": "XianyupeixunEdit", "props": true, "component": () => import("@/views/xianyu/XianyupeixunEditView.vue")},
    {"path": "/:role/xianyupeixun/archive/:xianyupeixunId", "name": "XianyupeixunArchive", "props": true, "component": () => import("@/views/xianyu/XianyupeixunArchiveView.vue")},
    // 校本培训
    {"path": "/:role/xiaobenpeixun/list/:status?&:schoolId?", "name": "XiaobenpeixunList", "props": true, "component": () => import("@/views/xiaoben/XiaobenpeixunListView.vue")},
    {"path": "/:role/xiaobenpeixun/detail/:xiaobenpeixunId", "name": "XiaobenpeixunDetail", "props": true, "component": () => import("@/views/xiaoben/XiaobenpeixunDetailView.vue")},
    {"path": "/:role/xiaobenpeixun/add", "name": "XiaobenpeixunAdd", "props": true, "component": () => import("@/views/xiaoben/XiaobenpeixunAddView.vue")},
    {"path": "/:role/xiaobenpeixun/edit/:xiaobenpeixunId", "name": "XiaobenpeixunEdit", "props": true, "component": () => import("@/views/xiaoben/XiaobenpeixunEditView.vue")},
    {"path": "/:role/xiaobenpeixun/archive/:xiaobenpeixunId", "name": "XiaobenpeixunArchive", "props": true, "component": () => import("@/views/xiaoben/XiaobenpeixunArchiveView.vue")},
    // 学科培训
    {"path": "/:role/xuekepeixun/list/:status?&:schoolId?", "name": "XuekepeixunList", "props": true, "component": () => import("@/views/xueke/XuekepeixunListView.vue")},
    {"path": "/:role/xuekepeixun/detail/:xuekepeixunId", "name": "XuekepeixunDetail", "props": true, "component": () => import("@/views/xueke/XuekepeixunDetailView.vue")},
    {"path": "/:role/xuekepeixun/add", "name": "XuekepeixunAdd", "props": true, "component": () => import("@/views/xueke/XuekepeixunAddView.vue")},
    {"path": "/:role/xuekepeixun/edit/:xuekepeixunId", "name": "XuekepeixunEdit", "props": true, "component": () => import("@/views/xueke/XuekepeixunEditView.vue")},
    {"path": "/:role/xuekepeixun/archive/:xuekepeixunId", "name": "XuekepeixunArchive", "props": true, "component": () => import("@/views/xueke/XuekepeixunArchiveView.vue")},


    // 作业督查
    {"path": "/:role/zuoyeducha/list", "name": "ZuoyeduchaList", "props": true, "component": () => import("@/views/zuoyeducha/ZuoyeduchaListView.vue")},
    {"path": "/:role/zuoyeducha/add", "name": "ZuoyeduchaAdd", "props": true, "component": () => import("@/views/zuoyeducha/ZuoyeduchaAddView.vue")},
    {"path": "/:role/zuoyeducha/detail/:zuoyeduchaId", "name": "ZuoyeduchaDetail", "props": true, "component": () => import("@/views/zuoyeducha/ZuoyeduchaDetailView.vue")},

    // 作业展评
    {"path": "/:role/zuoyezhanpin/list/:status?&:schoolId?", "name": "ZuoyezhanpingList", "props": true, "component": () => import("@/views/zuoyezhanping/ZuoyezhanpingListView.vue")},
    {"path": "/:role/zuoyezhanpin/detail/:zuoyezhanpingId", "name": "ZuoyezhanpingDetail", "props": true, "component": () => import("@/views/zuoyezhanping/ZuoyezhanpingDetailView.vue")},
    {"path": "/:role/zuoyezhanpin/add", "name": "ZuoyezhanpingAdd", "props": true, "component": () => import("@/views/zuoyezhanping/ZuoyezhanpingAddView.vue")},
    {"path": "/:role/zuoyezhanpin/edit/:zuoyezhanpingId", "name": "ZuoyezhanpingEdit", "props": true, "component": () => import("@/views/zuoyezhanping/ZuoyezhanpingEditView.vue")},
    {"path": "/:role/zuoyezhanpin/archive/:zuoyezhanpingId", "name": "ZuoyezhanpingArchive", "props": true, "component": () => import("@/views/zuoyezhanping/ZuoyezhanpingArchiveView.vue")},

    //作业科研
    {"path": "/:role/zuoyekeyan/list/:level?&:awardCase?", "name": "ZuoyekeyanList", "props": true, "component": () => import("@/views/zuoyekeyan/ZuoyekeyanListView.vue")},
    {"path": "/:role/zuoyekeyan/detail/:zuoyekeyanId", "name": "ZuoyekeyanDetail", "props": true, "component": () => import("@/views/zuoyekeyan/ZuoyekeyanDetailView.vue")},
    {"path": "/:role/zuoyekeyan/add", "name": "ZuoyekeyanAdd", "props": true, "component": () => import("@/views/zuoyekeyan/ZuoyekeyanAddView.vue")},

    // 数据统计
    {"path": "/:role/statistics/overview", "name": "StatisticsOverView", "props": true, "component": () => import("@/views/statistics/StatisticsOverView.vue")},
    {"path": "/:role/statistics/ranking/:rankingValue", "name": "StatisticsRanking", "props": true, "component": () => import("@/views/statistics/StatisticsRankingView.vue")},
    {"path": "/:role/statistics/data", "name": "StatisticsData", "props": true, "component": () => import("@/views/statistics/StatisticsDataView.vue")},
    {"path": "/:role/statistics/evaluate/list", "name": "StatisticsEvaluateList", "props": true, "component": () => import("@/views/statistics/StatisticsEvaluateListView.vue")},

    // 角色配置
    {"path": "/:role/class/list", "name": "ClassList", "props": true, "component": () => import("@/views/class/ClassListView.vue")},
    {"path": "/:role/teacher/list", "name": "TeacherList", "props": true, "component": () => import("@/views/teacher/TeacherListView.vue")},
    {"path": "/:role/teacher/add", "name": "TeacherAdd", "props": true, "component": () => import("@/views/teacher/TeacherAddView.vue")},
    {"path": "/:role/teacher/edit/:teacherId", "name": "TeacherEdit", "props": true, "component": () => import("@/views/teacher/TeacherEditView.vue")},
    {"path": "/:role/teacher/class/:teacherId", "name": "TeacherClass", "props": true, "component": () => import("@/views/teacher/TeacherClassView.vue")},

    {"path": "/*", "component": () => import("@/views/Error404View.vue")},
];

export default createRouter({"history": createWebHashHistory(), routes});
