<template>
    <form-dialog
        ref="dialogRef"
        title="请选择登录的组织"
        :submit-handle="onClickSubmit"
        submit-text="提交"
        :editable="false"
        :closeable="false"
        hide-submit-btn
    >
        <el-form-item v-for="sch in schools">
            <el-button @click="dialogRef.submit(sch.schoolId)">
                {{ sch.schoolName }}
            </el-button>
        </el-form-item>
    </form-dialog>
</template>


<script setup>
import {ref} from "vue";
const dialogRef = ref(null);
defineProps({"schools": Array});
</script>

<script>
import FormDialog from "./formDialog.vue";
export default FormDialog.createChild();
</script>

<style scoped>
    .el-form-item {margin-bottom: 10px;}
    .el-button {width: 100%;}
</style>
