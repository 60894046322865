import {defineStore} from "pinia";
import cacheget from "@/utils/fnCacheUtil";
import * as baseinfoApi from "@/api/baseinfo";

export default defineStore("baseinfo", {
    "actions": {
        getClassList(role, schoolId) {
            return cacheget(`classList:${schoolId}`, async () => {
                const {data} = await baseinfoApi.getClassList(role, schoolId);
                return data.map(this.parseClassName);
            });
        },
        getSchoolList() {
            return cacheget("schoolList", async () => {
                const {data} = await baseinfoApi.getSchoolList();
                return data || [];
            });
        },

        getTeacherList(schoolId,isRole) {
            return cacheget(`teachList.${schoolId}`, async () => {
                const {data} = await baseinfoApi.getTeacherList(schoolId,isRole);
                return data || [];
            });
        },

        parseClassName(classInfo) {
            classInfo.originName = classInfo.className;
            if (classInfo.className.startsWith(classInfo.grade)) {
                // {originName:"一年级1班", className:"一年级1班", shortName: "1班"}
                classInfo.className = classInfo.originName;
                classInfo.shortName = classInfo.originName.slice(classInfo.grade.length);
            } else {
                // {originName:"1班", className:"一年级1班", shortName: "1班"}
                classInfo.className = classInfo.grade + classInfo.originName;
                classInfo.shortName = classInfo.originName;
            }
            return classInfo;
        },
    },
});
