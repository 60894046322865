import {ElMessage} from "element-plus";
import axios from "axios";
import {useRoute} from "vue-router";

export const role = {
    toString() {
        if(window.location.pathname.includes("kiosk")){
            return "教育局长";
        }
        const route = useRoute(); // 在app上下文中才能获取
        return route?.params.role ||
            decodeURIComponent(window.location.hash.slice(2).split("/")[0]);
    },
};


axios.defaults.headers["Content-Type"] = "application/json";
const service = axios.create({
    "baseURL": import.meta.env.VITE_APP_BASE_API,
    "timeout": 100000,
});

service.interceptors.response.use(
    (res) => {
        if (
            res.request.responseType === "blob" ||
      res.request.responseType === "arraybuffer"
        ) {
            return res.data;
        }
        const code = res.data.code || 10200;
        if (code === 10200) return res.data;
        const msg = res.data.data || res.data.msg || `系统未知错误: ${code}`;
        if (!res.config.silence){
            ElMessage.error(msg);
        }
        return res.data;
    },
    ({message}) => {
        if (message === "Network Error") {
            ElMessage.error("网络错误，请检查网络后重试");
        } else if (message.includes("timeout")) {
            ElMessage.error("系统接口请求超时");
        } else if (message.includes("Request failed with status code")) {
            ElMessage.error("系统接口" + message.substr(message.length - 3) + "异常");
        } else {
            ElMessage.error(message);
        }
        return {"code": "00000"};
    },
);

export default service;
