<template>
    <div v-loading="isLoading&&!errMSG" element-loading-text="登录中..." class="container">
        <el-result v-if="errMSG" icon="error" title="应用打开失败">
            <template #sub-title>
                <p v-for="l in errMSG.split('\n')" class="row__msg">
                    <span v-if="!l.includes('-')">{{ l }}</span>
                    <template v-else>
                        <span class="field">{{ l.split('-')[0] }}</span>
                        <span>{{ l.split('-').slice(1).join('-') }}</span>
                    </template>
                </p>
            </template>
        </el-result>
        <router-view v-else-if="!isLoading" class="route-page" />
    </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
import dd from "dingtalk-jsapi";
import _ from "lodash";
import {openAuth} from "dingtalk-design-libs/biz/openAuth";
import * as accountApi from "@/api/account";
import SwitchOrgDialog from "@/components/switchOrgDialog.vue";

import useBaseinfoStore from "@/store/baseinfoStore";
const baseinfoStore = useBaseinfoStore();
import useAccountInfo from "@/store/accountStore";
const accountStore = useAccountInfo();

const errMSG = ref("");
const isLoading = ref(true);
const accountInfo = ref(null);

async function checkLogin(tokenXZQT, corpIdDingtalk) {
    if (tokenXZQT){
        // 学在青田Token登录
        const {data, msg} = await accountApi.loginXZQT(tokenXZQT);
        accountInfo.value = data;
        if (accountInfo.value){
            location.replace("/");
        }else {
            errMSG.value = msg;
        }
        return;
    }

    if (corpIdDingtalk && dd.env.platform !== "notInDingTalk"){
        // 学在青田钉钉登录
        // TODO 可以进一步区分是否原始钉钉登录，但是没必要
        if (dd.version < "7") {
            // 存在js兼容性问题
            errMSG.value = "当前钉钉版本过低，请更新客户端后使用";
            return;
        }
        const {"code": authCode} = await dd.runtime.permission.requestAuthCode({"corpId": corpIdDingtalk});
        const {data, msg, code} = await accountApi.loginDingtalk(authCode, corpIdDingtalk);
        if (code === 10200 && data.userId){
            accountInfo.value = data;
            const schools = _.uniqBy(data["roleList"].filter(r => r.schoolId), "schoolId");
            if (schools.length > 1){
                // 当前corpId有多个分校
                const selectSchoolId = await SwitchOrgDialog.open({schools});
                await accountApi.switchOrganization(selectSchoolId);
            } else if(schools.length === 1){
                // 当前corpid只有1个分校
                await accountApi.switchOrganization(schools[0].schoolId);
            }else if (data["roleList"].length === 1){
                // 当前corpId不是学校，是教育局
                await accountApi.switchOrganization(data["roleList"][0].eduBureauId);
            }
            location.replace("/");
            return;
        }
        if (code !== 10401){
            errMSG.value += "erre" + msg;
            return;
        }

        // 10401， 钉钉id未绑定用户信息, 接口会返回dingTalkId
        // 获取手机号继续绑定
        const {result, status} = await openAuth({
            "clientId": import.meta.env.VITE_DINGTALK_CLIENTID,
            "corpId": corpIdDingtalk,
            "rpcScope":"Contact.User.Read", 
            "fieldScope": "Contact.User.mobile",
            "type": 0,
        });
        if (status !== "ok"){
            errMSG.value += `用户授权手机号未成功, ${status}\n` + msg;
            return;
        }
        const mobileResp = await accountApi.loginDingtalkMobile(result.authCode, data.dingTalkId, corpIdDingtalk);
        if (mobileResp.code === 10200){
            // 绑定后保留corpId重新登录,
            location.reload();
            return;
        }
        errMSG.value = mobileResp.msg;
    }
}

onMounted(async function () {
    try{
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get("token");
        const corpId = queryParams.get("corpid");
        if (token || corpId){
            await checkLogin(token, corpId);
            return;
        }

        accountInfo.value = await accountStore.getAccountInfo();
        if (!accountInfo.value) {
            errMSG.value = "请在钉钉工作台搜索【作业控量管理】打开";
            return;
        }
        if (!accountInfo.value.roleList?.length){
            errMSG.value = "当前用户没有关联的角色，请联系管理员";
            return;
        }
        const schoolRole = accountInfo.value.roleList.find(r => r.schoolId&&r.role !== "家长");
        if (schoolRole){
            accountStore.schoolClassList = await baseinfoStore
                .getClassList(schoolRole.role, schoolRole.schoolId);
        }
        const eduRole = accountInfo.value.roleList.find(r => r.eduBureauId);
        if (eduRole){
            accountStore.schoolList = await baseinfoStore.getSchoolList(eduRole.eduBureauId);
        }
    } catch(e){
        errMSG.value += "catch: " +(e.message || e.errorMessage || JSON.stringify(e));
        console.error(e);
    }
    isLoading.value = false;
});
</script>
<style>
* {box-sizing: border-box; font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif; }
html, body, #app, .route-page {width: 100%; height: 100%; margin:0;padding:0;background-color: var(--el-bg-color-page);}
.route-page {display: flex; flex-direction: column;}
a{text-decoration: none; color: var(--el-menu-text-color); text-decoration: none;}

.el-form-item__content .el-select, .el-form-item__content .el-select-v2, .el-form-item__content .el-cascader, .el-form-item__content .el-date-editor.el-input{width: 100%;}
@media screen and (min-width: 960px) {
    body{
        width: 960px;
        margin: auto;
    }
}
</style>
<style scoped>
    .container {height: 100%;}
    .row__msg{text-align: left; display: flex;}
    .row__msg span {display: inline-block;}
    .field{min-width: 6em; max-width: 8em; margin-right: 6px;}
</style>
